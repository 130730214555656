.bank_account_opening_wrapper {
    display: flex;
}

.people {
    width: 50%;
}

.img img {
    width: 100%;
    height: 100%;
}

.text_block {
    width: 50%;
    background: #575656;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
}

.title {
    font-size: 32px;
    color: #fff;
    margin-bottom: 23px;
    width: 50%;
    text-align: left;
    margin-top: 50px;
}

.subtitle {
    width: 50%;
    font-size: 20px;
    color: #fff;
    margin-bottom: 12px;
    text-align: left;
}

.description {
    width: 50%;
    font-size: 17px;
    color: #fff;
    margin-bottom: 12px;
    text-align: left;
}

.list_item {
    font-size: 17px;
}

@media only screen and (max-width: 768px) {
    .bank_account_opening_wrapper {
        flex-direction: column;
    }

    .img {
        width: 100%;
        height: 100%;
    }

    .people{
        height: 100%;
        width: 100%;
    }

    .text_block {
        width: 100%;
    }

    .title {
        font-size: 32px;
        color: #fff;
        margin-bottom: 23px;
        width: 90%;
        text-align: center;
        margin-top: 50px;
    }

    .subtitle {
        text-align: center;
    }

    .description {
        width: 90%;
        font-size: 17px;
        color: #fff;
        margin-bottom: 12px;
        text-align: center;
    }

}
