.contact_wrapper {
    background-color: #F4F4F4;
    padding-top: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact_content {
    margin-right: 100px;
}

.title {
    font-family: 'Lexend Tera';
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 45px;
    text-transform: uppercase;
    color: #333333;
    margin-bottom: 40px;
}

.subtitle {
    font-family: 'Mitr';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 135%;
    color: #575656;
    margin-bottom: 45px;
}

.form_wrapper {
    max-width: 610px;
}

.inputs_block {
    display: flex;
}

.input {
    background: #FFFFFF;
    outline: none;
    width: 295px;
    height: 50px;
    border: none;
    padding-left: 10px;
    font-family: 'Mitr';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 135%;
    margin-bottom: 25px;
    box-sizing: border-box;
}

.textarea {
    background: #FFFFFF;
    outline: none;
    width: 100%;
    height: 120px;
    border: none;
    padding-left: 10px;
    font-family: 'Mitr';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 135%;
    margin-bottom: 25px;
    box-sizing: border-box;
}

.my_btn {
    padding: 13px 55px;
    border: none;
    cursor:pointer;
    background-color: #BF1120;
    font-family: 'Lexend Tera';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
}

.mr {
    margin-right: 20px;
}

.close {
    display: flex;
    justify-content: flex-end;
}

.close img {
    cursor: pointer;
}

.popup_content_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.popup_title {
    font-family: 'Lexend Tera';
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 45px;
    text-align: center;
    text-transform: uppercase;
    color: #333333;
    margin-top: 87px;
}

.popup_subtitle {
    font-family: 'Lexend Tera';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #575656;
    margin-top: 50px;
}

.popup_text {
    font-family: 'Mitr';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 135%;
    text-align: center;
    color: #575656;
    margin-top: 10px;
}

.popup_btn {
    background: #BF1120;
    border: none;
    width: 100px;
    height: 40px;
    margin-top: 50px;
    font-family: 'Lexend Tera';
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    text-transform: uppercase;
    color: #FFFFFF;
    cursor: pointer;
}

.contacts_text {
    font-size: 17px;
    color: #000;
    margin-bottom: 15px;
    font-family: 'Mitr';
    font-weight: 400;
}

.error {
    height: 25px;
    color: #BF1120;
    font-size: 25px;
    font-weight: 400;
}

.mapWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F4F4F4;
    padding-bottom: 20px;
}

.btn_wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
}

@media only screen and (max-width: 1240px) {
    .mapWrapper {
        display: none;
    }
}

@media only screen and (max-width: 768px) {

    .contact_wrapper {
        padding-top: 88px;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .inputs_block {
        flex-direction: column;
        margin: 0 16px;
    }

    .textarea {
        padding-left: 10px;
        width: 100%;
    }

    .mr {
        margin-right: 0;
    }

    .input {
        width: 100%;
    }

    .title {
        font-size: 20px;
        line-height: 25px;
        margin: 16px;
    }

    .subtitle {
        font-weight: 300;
        font-size: 14px;
        line-height: 135%;
        margin: 32px 0 24px 16px;
    }

    .textarea_block {
        margin: 0 16px;
    }

    .btn_wrapper {
        display: flex;
        justify-content: flex-end;
        margin-right: 16px;
    }

    .contact_content {
        padding-bottom: 88px;
    }

    .popup_title {
        font-size: 20px;
        margin-top: 16px;
    }

    .popup_subtitle {
        font-size: 12px;
        line-height: 15px;
        margin-top: 25px;
    }

    .popup_text {
        font-size: 14px;
        line-height: 135%;
        margin-top: 14px;
    }

    .popup_btn {
        width: 143px;
        height: 32px;
        margin-top: 25px;
        font-family: 'Lexend Tera';
    }


    .contactsData {
        margin-left: 20px;
    }

}
