.crypto_wrapper {
    z-index: -1;
    padding: 120px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.title {
    font-size: 32px;
    color: #000;
    margin-bottom: 50px;
    width: 330px;
    text-align: center;
}

.desc {
    font-size: 17px;
    color: #000;
    width: 330px;
    text-align: center;
}

.desc a {
    color: #000;
}

@media only screen and (max-width: 600px) {



}
