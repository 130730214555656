.footer_wrapper_web {
    width: 100%;
    background-color: #565656;
}

.footer_container {
    max-width: 1240px;
    margin: 0 auto;
    padding:90px 0 18px 0 ;
}

.footer_content {
    margin-bottom: 56px;
    color: #fff;
}

@media only screen and (max-width: 768px) {
    .footer_content {
        margin-left: 20px;
    }
}
