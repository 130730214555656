.header_wrapper_web {
    background-color: #575656;
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    border-bottom: 1px solid #fff;
}

.header {
    display: flex;
    max-width: 1240px;
    margin: 0 auto;
    align-items: center;
    flex-direction: column;
}

.logo {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin-bottom: 15px;
}

.contacts {

}

.contactsData {
    font-family: 'Mitr';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #ffffff;
    cursor: pointer;
}

.nav {
    font-family: 'Mitr';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #ffffff;
    cursor: pointer;
    margin-bottom: 5px;
}

.nav a {
    padding-right: 15px;
    margin-left: 15px;
    border-right: 1px solid #fff;
}

.nav a:last-child {
    margin-right: 0;
    border-right: none;
}

.active {
    color: #848080;
}

.active a {
    margin-right: 29px;
}

.active a:last-child {
    margin-right: 0;
}

.header_wrapper_mobile {
    display: none;
}

.stamp {
    margin-left: 20px;
    margin-top: 10px;
}

@media only screen and (max-width: 768px) {

    .header_wrapper_web {
        display: none;
    }

    .header {
        display: none;
    }

    .header_wrapper_mobile {
        display: flex;
        position: fixed;
        box-sizing: border-box;
        width: 100%;
        z-index: 100;
        background-color: #E5E5E5;
        justify-content: space-between;
        align-items: center;
        overflow:hidden;
    }


    .logo_mobile {
        width: 153px;
        height: 42px;
    }

    .hamburger_menu_block {
        margin-right: 15px;
    }

    .mobile_nav {
        position: fixed;
        width: calc(100% - 153px);
        right: 0;
        top: 0;
        height: 100vh;
        background-color: #BF1120;
        z-index: 100;
        padding: 15px;
        box-sizing: border-box;
        transition:500ms;
    }

    .none {
        position: fixed;
        box-sizing: border-box;
        height: 100vh;
        width: 1px;
        top: 0;
        right: -100%;
        transition:900ms;
        padding: 0;
    }

    .test {
        color: #fff;
        width: 50px;
        height: 50px;
    }

    .mobile_nav_content {

    }

    .close_block {
        display: flex;
        justify-content: flex-end;
    }

    .mobile_nav_link_wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 40px;
    }

    .mobile_nav_link_wrapper {
        font-family: 'Mitr';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 135%;
        text-align: center;
        color: #FFFFFF;
    }

    .mobile_nav_link_wrapper a {
        margin-bottom: 25px;
    }

    .active_mobile {
        color: #333333;
    }

    .mobile_nav_social_wrapper {
        display: flex;
        justify-content: center;
    }

    .mobile_nav_social_wrapper a {
        margin-right: 24px;
    }

    .mobile_nav_social_wrapper a:last-child {
        margin-right: 0;
    }
}



