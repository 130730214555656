.myModal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: none;
    background: rgba(0, 0, 0, 0.5);
}

.myModalContent {
    padding: 25px;
    background: white;
    border-radius: 16px;
    width: 610px;
    height: 510px;
}

.myModal.active {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 600px) {
    .myModalContent {
        padding: 16px;
        width: 288px;
        height: 258px;
    }
}
