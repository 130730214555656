.formation_wrapper {

}

.formation_content {
    max-width: 1028px;
    margin: 0 auto;
    padding: 100px 20px 0;

}

.title {
    font-family: 'Lexend Tera';
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 45px;
    text-transform: uppercase;
    color: #333333;
    margin-bottom: 40px;
}

.text {
    font-family: 'Mitr';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 135%;
    color: #575656;
    width: 715px;
    margin-bottom: 45px;
}

.formation_tabs {
    margin-bottom: 30px;
}

.img_block {
    margin-top: 171px;
    width: 100%;
    height: 551px;
    background-color: #2F2065;
    position: relative;
}

.img {
    position: absolute;
    bottom: 50px;
    left:50%;
    transform:translate(-50%);
}

.img_block_mobile {
    display: none;
}

@media only screen and (max-width: 600px) {

    .title {
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 32px;
        margin-left: 16px;
    }

    .formation_content {
        max-width: 1240px;
        margin: 0 auto;
        padding-top: 88px;
    }

    .text {
        font-family: 'Mitr';
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 135%;
        width: auto;
        margin: 0 16px 32px;
    }

    .img_block {
        display: none;
    }

    .img_block_mobile {
        display: flex;
        justify-content: center;
        background-color: #2F2065;
        position: relative;
        min-height: 1014px;
        margin-top: 116px;
    }

    .img_mobile {
        position: absolute;
        bottom: 24px;
    }

}
