.main_wrapper {
    width: calc(100vw);
    height: 566px;
    background-image: url("../../assets/img/mainBg.webp");
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 110px;
    margin-top: 67px;
    opacity: .85;
    filter: grayscale(100%);
    position: relative;
}

.overlay {
    position: absolute;
    width:100%;
    height: calc(100% - 110px);
    background:#000;
    opacity:0.5;
    z-index: -1;
}

.main_container {
    max-width: 1028px;
    margin: 0 auto;
    z-index: 1;
}

.content {
    position: relative;
}

.title {
    font-family: 'Lexend Tera';
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 1.8em;
    text-align: right;
    text-transform: uppercase;
    color: #fcfcfc;
    margin-top: 0;
}

.subtitle {
    font-family: 'Mitr';
    font-style: normal;
    font-weight: 300;
    font-size: 34px;
    line-height: 135%;
    margin-top: 45px;
    text-align: right;
    color: #fff;
}

.text {
    margin-top: 25px;
    font-family: 'Mitr';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 135%;
    color: #8A8A8A;
    width: 700px;
}

.text_block {
    display: flex;
    justify-content: center;
    margin-bottom: 55px;
}

.btn_block {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
}

.myBtn {
    width: 300px;
    height: 50px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background: #40BABC;
    font-family: 'Mitr';
    color: #fff;
    transition: .5s all ease;
}

.myBtn:hover {
    background: #554A58;
    transition: .5s all ease;
}

.cards_block {
    display: flex;
    position: relative;
}

.card {
    width: 295px;
    z-index: 2;
    padding: 35px 25px 25px 25px;
    margin-right: 20px;
}

.card:last-child {
    margin-right: 0;
}



.card_title {
    font-family: 'Lexend Tera';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 10px;
}

.card_desc {
    font-family: 'Mitr';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 135%;
    color: #FFFFFF;
    margin-bottom: 40px;
    min-height: 132px;
}

.effective_block {
    position: absolute;
    bottom: -280px;
    background: #575656;
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Lexend Tera';
    font-style: normal;
    font-size: 32px;
    color: #fff;
    letter-spacing: -0.1em;
}

@media only screen and (max-width: 1440px) {

    .main_wrapper {
        height: auto;
        width: auto;
        padding-bottom: 10px;
    }

    .title {
        font-family: 'Lexend Tera';
        font-style: normal;
        font-weight: 900;
        font-size: 30px;
        line-height: 1.8em;
        text-align: right;
        text-transform: uppercase;
        color: #fcfcfc;
        margin-top: 0;
    }

    .subtitle {
        font-family: 'Mitr';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 135%;
        color: #fff;
        width: auto;
        margin: 32px 16px 0;
    }

    .btn_block {
        margin-bottom: 25px;
    }

    .effective_block {
        bottom: -100px;
    }
}


@media only screen and (max-width: 768px) {
    .overlay {
        position: absolute;
        top: 0px;
        width:100%;
        height: 100%;
        background:#000;
        opacity:0.5;
        z-index: -1;
    }

    .btn_block {
        justify-content: center;
    }

    .title {
        text-align: center;
    }

    .subtitle {
        text-align: center;
    }


    .effective_block {
        position: absolute;
        bottom: -125px;
        background: #575656;
        width: 100%;
        height: 125px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Lexend Tera';
        font-style: normal;
        font-size: 32px;
        color: #fff;
        letter-spacing: -0.1em;
    }
}

